// Required Core Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";


.tps-testimonials {
    &-wrapper {
        .glide__bullet {
            background: transparent;
            border: rem-calc(1) solid $color-blue-orient;
            width: rem-calc(10);
            height: rem-calc(10);
            cursor: pointer;
            outline-color: $color-yellow-lightning;
            outline-offset: rem-calc(3);

            &--active {
                background: $color-blue-orient;
            }
        }
    }

    &-headline {
        color: $color-brand-blue-dark;
        font-size: rem-calc(50);
        line-height: 1.2;
        margin-bottom: rem-calc(40);
        text-align: center;

        @include breakpoint(tablet) {
            font-size: rem-calc(55);
            line-height: 1.54;
            margin-bottom: rem-calc(25);
        }
    }

    &-list {
        margin-bottom: rem-calc(35);

        @include breakpoint(tablet) {
            margin-bottom: rem-calc(21);
        }

        &:not(.glide__track) {
            display: flex;
        }

        &-item {
            background-color: $color-brand-blue-light;
            padding: rem-calc(40) rem-calc(17.5);

            @include breakpoint(large) {
                padding: rem-calc(50) rem-calc(27.5);
            }

            &-image {
                margin-bottom: rem-calc(20);
            }

            &-headline {
                color: $color-brand-blue-dark;
                font-size: rem-calc(30);
                font-weight: $font-weight-bold;
                line-height: 1.33;
                margin-bottom: rem-calc(25);
            }

            &-text {
                margin-bottom: rem-calc(35);


                & > *:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
