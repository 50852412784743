.tps-find-location {
    @include breakpoint(tablet) {
        display: flex;
    }

    &-left {
        @include breakpoint(tablet) {
            width: 45%;
            padding-right: rem-calc(30);
        }

        @include breakpoint(large) {
            padding-right: rem-calc(100);
        }
    }

    &-right {
        @include breakpoint(tablet) {
            width: 55%;
        }
    }

    &-header {
        font-weight: 700;
        font-size: rem-calc(35);
        line-height: 1.55;
        color: $color-brand-blue-dark;

        @include breakpoint(tablet) {
            font-size: rem-calc(40);
        }
    }

    &-filters {
        margin-top: rem-calc(42);

        &-current {
            margin-bottom: rem-calc(45);
            width: 100%;

            &::after {
                display: none !important;
            }

            span {
                margin-left: rem-calc(12);
            }
        }

        &-divider {
            display: flex;
            align-items: center;
            margin-bottom: rem-calc(30);
            text-transform: uppercase;
            font-size: rem-calc(18);
            line-height: 1;
            color: $black;

            &::before, &::after {
                flex-grow: 1;
                content: '';
                display: inline-block;
                height: rem-calc(1);
                background-color: $color-gray-dusty;
            }

            &::before {
                margin-right: rem-calc(20);

                @include breakpoint(tablet) {
                    margin-right: rem-calc(12);
                }
            }

            &::after {
                margin-left: rem-calc(20);

                @include breakpoint(tablet) {
                    margin-left: rem-calc(12);
                }
            }
        }

        &-search {
            display: flex;

            &-label {
                margin-bottom: rem-calc(7);
                font-size: rem-calc(18);
                line-height: rem-calc(28);
            }

            &-input {
                margin: 0;
                padding: rem-calc(10) rem-calc(18);
                border: rem-calc(1) solid $color-gray-alto;
                border-right: 0;
                height: auto;
                font-size: rem-calc(18);
            }

            &-submit {
                flex-shrink: 0;
                margin: 0;
                width: rem-calc(51);
                background-color: $color-brand-blue-dark;
                color: $white;
                cursor: pointer;
            }
        }
    }

    &-results {
        display: flex;
        flex-wrap: wrap;
        margin-top: rem-calc(30);

        @include breakpoint(tablet) {
            height: rem-calc(444.46);
            overflow-y: scroll;
        }

        &-item {
            padding: rem-calc(40) 0;
            width: 100%;
            border-bottom: rem-calc(1) solid $color-gray-dusty;

            &-title {
                font-size: rem-calc(30);
                color: $color-brand-blue-dark;
                font-weight: 700;
            }

            &-info {
                margin-top: rem-calc(13);
                font-size: rem-calc(18);
                line-height: 1.56;
            }
        }
    }

    &-map {
        width: 100%;
        height: rem-calc(300);

        @include breakpoint(tablet) {
            height: 100%;
        }

        &-infowindow {
            max-width: rem-calc(200);
            font-size: rem-calc(18);
            color: $color-gray-fuscous;
            font-family: $font-family-sans;
            font-weight: 400;
        }
    }
}
