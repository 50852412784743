.tps-resource-cards {
    $cards-gap: rem-calc(20);
    margin: 0 #{-$cards-gap/2} #{-$cards-gap} #{-$cards-gap/2};

    @include breakpoint(tablet) {
        display: flex;
        flex-wrap: wrap;
    }

    &-item {
        display: flex;
        flex-direction: column;
        margin: 0 $cards-gap/2 $cards-gap $cards-gap/2;
        width: calc(100% - #{$cards-gap});

        @include breakpoint(tablet) {
            width: calc(33.33% - #{$cards-gap}); // third : default
        }

        &--size-half {
            @include breakpoint(tablet) {
                width: calc(50% - #{$cards-gap});
            }
        }

        &--size-quarter {
            @include breakpoint(tablet) {
                width: calc(25% - #{$cards-gap});

                .tps-resource-cards-item-info {
                    display: block;

                    > span {
                        display: none;
                    }
                }
            }
        }

        &-image {
            width: 100%;
            padding-bottom: 62.5%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }

        &-content {
            margin: rem-calc(-30) rem-calc(10) 0 0;
            padding: rem-calc(40) rem-calc(15) rem-calc(50) rem-calc(15);
            background: $white;

            @include breakpoint(tablet) {
                flex-grow: 1;
            }

            @include breakpoint(large) {
                margin: rem-calc(-50) rem-calc(30) 0 0;
                padding: rem-calc(50) rem-calc(30);
            }

            h2, h3, h4 {
                margin: 0;
            }
        }

        &-category {
            display: inline-block;
            padding: rem-calc(2.75) rem-calc(5.5);
            background-color: $color-brand-yellow;
            font-size: rem-calc(14);
            line-height: 1;
            letter-spacing: rem-calc(1);
            color: $black;
            text-transform: uppercase;
            vertical-align: middle;
        }

        &-title {
            display: block;
            margin-top: rem-calc(15);
            font-size: rem-calc(22);
            line-height: rem-calc(32);
            color: $color-brand-blue-dark;
            text-decoration: underline;
        }

        &-info {
            margin-top: rem-calc(5);
            font-size: rem-calc(16);
            line-height: 1.75;
            color: $color-gray-fuscous;

            @include breakpoint(tablet) {
                display: grid;
                grid-template-columns: minmax(#{rem-calc(80)}, max-content) min-content 1fr;
            }
        }

        &-description {
            margin-top: rem-calc(15);
            font-size: rem-calc(18);
            line-height: 1.6;

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }
}
