.button {
    text-decoration: none;

    // Default navy solid
    &:not(.button-link) {
        @include button-solid($color-brand-blue-dark, $color-white-absolute);
    }

    &.navy {
        @include button-solid($color-brand-blue-dark, $color-white-absolute);
    }

    &.navy.hollow {
        @include button-outline($color-brand-blue-dark, $color-white-absolute);
    }

    &.yellow {
        @include button-solid($color-brand-yellow, $color-brand-blue-dark);
    }

    &.yellow.hollow {
        @include button-outline($color-brand-yellow, $color-brand-blue-dark);
    }

    &.white {
        @include button-solid($color-white-absolute, $color-brand-blue-dark);
    }

    &.white.hollow {
        @include button-outline($color-white-absolute, $color-brand-blue-dark);
    }

}

.button-link {
    @include button-chevron-forward($anchor-color, $anchor-color-hover);
}

.button-link-pdf {
    @include button-link-fa-icon-left('\f1c1', $black);
}

.button-link-line-bottom {
    padding-bottom: rem-calc(3);
    border-bottom: rem-calc(1) solid $color-blue-orient;
}
