
.tps-site-off-canvas {
    max-height: 100%;
    height: auto;
    //overflow-y: visible;

    &-close-button {
        color: $color-white;
        font-size: rem-calc(22);
        top: rem-calc(9);
        right: rem-calc(9);
        padding: rem-calc(7);
        position: absolute;
    }

    &-utility-menu {
        padding: rem-calc(11) 0;


        &-list {

            .tps-site-off-canvas-utility-menu-list-item > & {
                display: none;
            }

            .expanded > & {
                display: block;
            }

            &-item {
                position: relative;

                &-link,
                a:not(.button):not(.button-link) {
                    display: block;
                    font-weight: $font-weight-medium;
                    line-height: rem-calc(28);
                    padding: rem-calc(11) rem-calc(22);
                    text-decoration: none;

                    &.tps-menu-link-toggle {
                        padding-left: rem-calc(5);
                        padding-right: rem-calc(15);
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
            }
        }
    }

    .tps-site-header-search {
        background-color: $color-brand-blue-dark;
        padding: rem-calc(44) rem-calc(22) rem-calc(20);

        .tps-site-header-search-submit {
            background-image: url('../img/search-icon-white.svg');
        }

        .tps-site-header-search-input {
            background-color: $color-blue-prussian;
            border-color: $color-blue-prussian;
            color: $color-white;

            &::placeholder {
                color: $color-white;
            }
        }
    }
}
