.tps-timeline {
    @include breakpoint(tablet) {
        display: flex;
    }

    &-left, &-right {
        @include breakpoint(tablet) {
            display: flex;
            align-items: center;
            width: 50%;

            > div {
                max-width: $grid-container-max / 2;
            }
        }
    }

    &-left {
        background-color: $color-brand-blue-light;

        @include breakpoint(tablet) {
            justify-content: flex-end;

            > div {
                padding-right: rem-calc(40);
            }
        }

        @include breakpoint(large) {
            > div {
                padding-left: 0;
                padding-right: rem-calc(90);
            }
        }
    }

    &-right {
        background-color: $color-brand-yellow;

        > div {
            @each $bp, $padding in $grid-container-padding {
                @include breakpoint($bp) {
                    padding-left: $padding / 2;
                    padding-right: $padding / 2;
                }
            }
        }

        @include breakpoint(tablet) {
            order: 1;

            > div {
                padding-left: rem-calc(40);
            }
        }

        @include breakpoint(large) {
            > div {
                padding-right: 0;
                padding-left: rem-calc(90);
            }
        }
    }

    &-shared-description {
        padding-top: rem-calc(50);
        padding-bottom: rem-calc(50);
        line-height: 1.55;

        &-title {
            margin-bottom: rem-calc(16);
            font-size: rem-calc(40);
            line-height: inherit;
            font-weight: 700;
            color: $color-brand-blue-dark;
        }

        &-content {
            font-size: rem-calc(18);
            color: $black;

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-years {
        position: relative;
        padding-top: rem-calc(50);
        padding-bottom: rem-calc(30);

        $desktop-year-width: 162;

        &-line {
            position: absolute;
            top: 0;
            left: rem-calc(40);
            width: rem-calc(4);
            height: 100%;
            background: $white;

            @include breakpoint(large) {
                left: rem-calc($desktop-year-width);
            }
        }

        &-item {
            position: relative;
            padding: rem-calc(10) rem-calc(15) rem-calc(10) rem-calc(40 + 4 + 40);

            @include breakpoint(large) {
                display: flex;
                padding: rem-calc(30) 0;
            }

            &-date {
                margin-bottom: rem-calc(10);
                font-size: rem-calc(40);
                line-height: 1;
                font-weight: 700;
                color: $color-brand-blue-dark;

                @include breakpoint(large) {
                    margin-bottom: 0;
                    flex-shrink: 0;
                    flex-grow: 0;
                    width: rem-calc($desktop-year-width);
                    padding-left: rem-calc(15);
                }
            }

            &-content {
                font-size: rem-calc(18);
                line-height: 1.55;
                color: $black;

                & > *:last-child {
                    margin-bottom: 0;
                }

                @include breakpoint(large) {
                    padding-left: rem-calc(50);
                }
            }

            &-dot {
                position: absolute;
                top: rem-calc(20);
                left: rem-calc(40 + 2 - 15);
                width: rem-calc(30);
                height: rem-calc(30);
                border-radius: 50%;
                background-color: $white;

                @include breakpoint(large) {
                    top: rem-calc(34);
                    left: rem-calc($desktop-year-width + 2 - 15);
                }
            }
        }
    }
}
