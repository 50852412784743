.tps-icon-callouts {
    $cards-gap: rem-calc(30);
    $cards-gap-large: rem-calc(90);
    margin: 0 #{-$cards-gap/2} #{rem-calc(-60)} #{-$cards-gap/2};

    @include breakpoint(tablet) {
        display: flex;
        flex-wrap: wrap;
    }

    @include breakpoint(large) {
        margin-right: -$cards-gap-large/2;
        margin-left: -$cards-gap-large/2;
    }

    &-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 $cards-gap/2 rem-calc(60) $cards-gap/2;
        width: calc(100% - #{$cards-gap});

        @include breakpoint(tablet) {
            .tps-icon-callouts--per-row-2 & {
                width: calc(50% - #{$cards-gap});
            }

            .tps-icon-callouts--per-row-3 & {
                width: calc(33.33% - #{$cards-gap});
            }
        }

        @include breakpoint(large) {
            margin-right: $cards-gap-large/2;
            margin-left: $cards-gap-large/2;

            .tps-icon-callouts--per-row-2 & {
                width: calc(50% - #{$cards-gap-large});
            }

            .tps-icon-callouts--per-row-3 & {
                width: calc(33.33% - #{$cards-gap-large});
            }
        }

        &-image {
            width: auto;
            height: rem-calc(52);
        }

        &-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &-footer {
                margin-top: auto;
            }
        }

        &-header {
            margin: rem-calc(22) 0 0 0;
            font-size: rem-calc(22);
            font-weight: 700;
            line-height: 1.45;
            color: $black;
        }

        &-description {
            margin-top: rem-calc(25);
            font-size: rem-calc(18);
            line-height: 1.56;
            color: $black;

            ul li::marker {
                color: $color-brand-blue-dark;
            }

            > *:last-child {
                margin-bottom: 0;
            }
        }

        &-button {
            display: inline-block;
            margin-top: rem-calc(22);
            text-decoration: underline;

            @include breakpoint(tablet) {
                margin-top: rem-calc(36);
            }
        }
    }
}
