
html,
body {
    min-height: 100%;
    height: 100%;
}



.tps-site {

    &-bypass-link {
        background-color: $color-white;
        position: fixed;
        z-index: 91;
        // hide the link
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: rem-calc(1);
        overflow: hidden;
        padding: 0;
        width: rem-calc(1);
        white-space: nowrap;

        // show the link on focus
        &:focus, &:focus-visible {
            clip: auto;
            height: auto;
            padding: rem-calc(5) rem-calc(10);
            width: auto;
        }
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    &-header,
    &-footer {
        flex: 0 0 auto;
    }

    &-content {
        flex: 1 0 auto;
        padding-top: rem-calc(157.5);

        @include breakpoint(large) {
            padding-top: rem-calc(144);
        }

        body.tps-header-sticky & {
            @include breakpoint(large) {
                padding-top: rem-calc(54)
            }
        }
    }

    &-bypass-link {
        left: rem-calc(-999);
        position: absolute;
        top: auto;
        width: rem-calc(1);
        height: rem-calc(1);
        overflow: hidden;
        z-index: -999;

        &:focus {
            background-color: $white;
            left: 0;
            top: 0;
            width: auto;
            height: auto;
            overflow: auto;
            padding: rem-calc(5);
            z-index: 999;
        }
    }
}
