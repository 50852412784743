




// file upload

.ktc-file-component-wrapper,
.tps-file-component-wrapper {
    position: relative;
    display: block;
    max-width: rem-calc(385);

    label {
        background-color: $color-brand-gray-light;
        padding: rem-calc(8) rem-calc(15);
        padding-right: rem-calc(60);
        display: block;
        color: $color-black;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:before {
            content: "\f0c6";
            font-family: $font-icons;
            display: inline-block;
            vertical-align: top;
            margin-right: rem-calc(12.5);
        }

        .section-bg-beige & {
            background-color: $color-white;
        }
    }

    [type="file"] {
        border: 0;
        clip: rect(0, 0, 0, 0);
        margin: 0;
        overflow: hidden;
        position: absolute;
        height: rem-calc(1);
        width: rem-calc(1);
    }

    .ktc-uploader-replacement {
        display: none;
    }

    .ktc-uploader-text-button {
        position: absolute;
        right: rem-calc(23);
        top: rem-calc(16);
        display: block;
        height: rem-calc(16);
        width: rem-calc(16);
        overflow: hidden;
        text-indent: rem-calc(17);

        &:before {
            content: "\f00d";
            font-family: $font-icons;
            font-weight: $font-weight-light;
            position: absolute;
            font-size: rem-calc(20);
            top: 0;
            left: 0;
            line-height: rem-calc(16);
            text-indent: 0;
            width: rem-calc(16);
            text-align: center;
        }

        &[hidden="hidden"] {
            display: none;
        }
    }
}

// TPS Form Section styles
.tps-form {

    &-validation-summary {
        ul {
            margin: 0;
            list-style: none;
        }
    }

    &-section {

        &-inner {
            margin-right: rem-calc(-8);
            margin-left: rem-calc(-8);

            @include breakpoint(large) {
                margin-right: rem-calc(-10);
                margin-left: rem-calc(-10);
            }
        }

        &-column {

            label {
                margin-bottom: rem-calc(8.5);

                &.required {
                    &::after {
                        content: '*';
                    }
                }
            }

            padding-left: rem-calc(8);
            padding-right: rem-calc(8);
            margin-bottom: rem-calc(28);

            @include breakpoint(large) {
                padding-left: rem-calc(10);
                padding-right: rem-calc(10);
            }

            &:last-child {
                margin-bottom: rem-calc(20);

                @include breakpoint(large) {
                    margin-bottom: rem-calc(20);
                }
            }
        }
    }
}

// styles for kentico default form section (just in case)
.ktc-default-section {
    margin-bottom: rem-calc(28);
}


// using the form section parent so that
// styles do not effect kentico admin
.tps-form-section,
.ktc-default-section {

    [type='text'],
    [type='password'],
    [type='date'],
    [type='datetime'],
    [type='datetime-local'],
    [type='month'],
    [type='week'],
    [type='email'],
    [type='number'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='url'],
    [type='color'],
    textarea,
    select {
        height: rem-calc(42);
        margin-bottom: rem-calc(5);

        &.input-validation-error {
            border-color: $alert-color;
        }

        &:focus,
        &:focus-visible {
            outline: rem-calc(5) auto Highlight;
            outline: rem-calc(5) auto -webkit-focus-ring-color;
        }

        &[hidden] {
            display: none;
        }
    }

    .field-validation {
        &-error {
            color: $alert-color;
        }
    }

    textarea {
        max-width: rem-calc(485);
        min-height: rem-calc(175);
    }

    select {
        background-image: url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M5.34375 7.37585H5.30859C5.37891 7.44617 5.48438 7.48132 5.625 7.48132C5.73047 7.48132 5.83594 7.44617 5.94141 7.37585L11.1445 2.20789C11.2148 2.13757 11.25 2.0321 11.25 1.92664C11.25 1.82117 11.2148 1.7157 11.1445 1.61023L10.4414 0.907104C10.3359 0.836792 10.2305 0.801636 10.125 0.801636C9.98438 0.801636 9.91406 0.836792 9.84375 0.907104L5.625 5.0907L1.40625 0.907104C1.33594 0.836792 1.23047 0.801636 1.125 0.801636C0.984375 0.801636 0.878906 0.836792 0.808594 0.907104L0.105469 1.61023C0.0351562 1.7157 0 1.82117 0 1.92664C0 2.0321 0.0351562 2.13757 0.140625 2.20789L5.34375 7.37585Z" fill="black" /></svg>');
        background-position: right rem-calc(-25) center;
        padding-right: rem-calc(50);
        max-width: rem-calc(485);
    }

    [type="radio"],
    [type="checkbox"] {
        border: 0;
        clip: rect(0, 0, 0, 0);
        margin: 0;
        overflow: hidden;
        position: absolute;
        height: rem-calc(1);
        width: rem-calc(1);

        & + label,
        // fix for bizform checkbox list
        & + [type="hidden"] + label {
            margin-left: 0;
            margin-bottom: 0;
            padding-left: rem-calc(40);
            position: relative;

            &:before {
                background-color: $color-white;
                border: rem-calc(1) solid $color-brand-gray-medium;
                content: "";
                display: inline-block;
                margin: rem-calc(5) rem-calc(17) rem-calc(5) 0;
                position: absolute;
                left: 0;
                height: rem-calc(22);
                width: rem-calc(22);
                vertical-align: top;
                z-index: 1;
            }
        }

        &.input-validation-error + label,
        // fix for bizform checkbox list
        &.input-validation-error + [type="hidden"] + label {
            &:before {
                border-color: $alert-color;
            }
        }

        &:focus + label,
        &:focus-visible + label,
        // fix for bizform checkbox list
        &:focus + [type="hidden"] + label,
        &:focus-visible + [type="hidden"] + label {
            &:before {
                outline: rem-calc(4) auto Highlight;
                outline: rem-calc(4) auto -webkit-focus-ring-color;
            }
        }


        &:disabled {
            & + label,
            // fix for bizform checkbox list
            & + [type="hidden"] + label {
                color: $color-brand-gray-dark;
                cursor: not-allowed;

                &:before {
                    background-color: $color-brand-gray-medium;
                }
            }
        }
    }

    [type="radio"] {
        & + label,
        // fix for bizform checkbox list
        & + [type="hidden"] + label {
            &:before {
                border-radius: rem-calc(11);
            }
        }

        &:checked {
            & + label,
            // fix for bizform checkbox list
            & + [type="hidden"] + label {
                &:after {
                    background-color: $color-brand-blue-dark;
                    border-radius: rem-calc(8);
                    content: "";
                    display: inline-block;
                    position: absolute;
                    left: rem-calc(6);
                    top: rem-calc(11);
                    height: rem-calc(10);
                    width: rem-calc(10);
                    z-index: 2;
                }
            }
        }
    }

    [type="checkbox"]:checked {
        & + label,
        // fix for bizform checkbox list
        & + [type="hidden"] + label {
            &:before {
                content: "\f00c";
                color: $color-brand-blue-dark;
                font-family: $font-icons;
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                text-align: center;
            }
        }
    }

    .ktc-radio,
    .ktc-checkbox {
        display: block;
        position: relative;
        margin-bottom: rem-calc(16.5);

        &-list {
            display: block;
            margin-top: rem-calc(16.5);
        }
    }
    // styles for formbuilder in kentico admin:
    .ktc-field-wrapper {
        display: block;
    }

    .ktc-form-group {
        padding: 0 !important;
    }

    .ktc-editing-form-icon-cell,
    .ktc-editing-form-label-cell,
    .ktc-editing-form-value-cell {
        text-align: left !important;
        float: none !important;
        padding: 0 !important;
        white-space: normal !important;
        min-height: 0 !important;
        width: 100% !important;

        label {
            text-align: left !important;
            padding-left: 0;
            margin-right: 0;
        }
    }
}


[type="submit"] {
    .tps-form-section + &,
    .ktc-default-section + & {
        @extend .button;
    }
}

// add focus state on switch
.switch {
    border-radius: rem-calc(10);
    font-size: rem-calc(14);
    height: rem-calc(20);

    &-paddle {
        background-color: $dark-gray;
        color: $dark-gray;
        font-weight: $font-weight-regular;
        position: relative;
        border-radius: rem-calc(10);
        height: rem-calc(20);
        width: rem-calc(41.5);

        &:after {
            border-radius: rem-calc(8);
            top: rem-calc(2);
            right: auto;
            left: rem-calc(2);
            width: rem-calc(16);
            height: rem-calc(16);
            transition: left ease-in-out 0.25s;
        }

        input:checked + &,
        input:checked ~ & {

            &:after {
                left: rem-calc(23.5);
                transition: left ease-in-out 0.25s;
            }
        }

        input:focus + &,
        input:focus-visible + & {
            outline: rem-calc(1) auto Highlight;
            outline: rem-calc(1) auto -webkit-focus-ring-color;
            outline-offset: rem-calc(1);
        }
    }
}

