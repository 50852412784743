.tps-billboard {
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
    }

    &--tall::before {
        top: rem-calc(20);
        bottom: 0;

        @include breakpoint(tablet) {
            top: 0;
            bottom: rem-calc(20);
        }
    }

    &--short::before {
        top: 0;
        bottom: rem-calc(20);
    }

    &--navy {
        &::before {
            background-color: $color-brand-blue-dark;
        }

        .orbit-bullets button {
            background-color: $color-brand-yellow;
        }
    }

    &--yellow {
        &::before {
            background-color: $color-brand-yellow;
        }

        .orbit-bullets button {
            background-color: $color-brand-blue-dark;
        }
    }

    &-slide {
        > .grid-container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            position: relative;
            width: 100%;

            @include breakpoint(tablet) {
                .tps-billboard--tall & {
                    min-height: rem-calc(510);
                }

                .tps-billboard--short & {
                    min-height: rem-calc(300);
                }
            }
        }

        &-content {
            padding: rem-calc(35) 0;

            > *:last-child {
                margin-bottom: 0;
            }

            .tps-billboard--short & {
                padding-top: rem-calc(45);
            }

            @include breakpoint(tablet) {
                padding-right: rem-calc(40);
                padding-bottom: rem-calc(35 + 20);
                width: 50%;

                .tps-billboard--has-slides & {
                    padding-bottom: rem-calc(80 + 20);
                }

                .tps-billboard--short & {
                    padding-top: rem-calc(35);
                    padding-bottom: rem-calc(35 + 20);
                }
            }
        }

        &-image {
            padding-bottom: 75%; // roughly 300 x 230
            width: calc(100% + #{rem-calc(10)});
            margin-right: rem-calc(-10);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            @include breakpoint(medium) {
                width: calc(100% + #{rem-calc(15)});
                margin-right: rem-calc(-15);
            }

            @include breakpoint(tablet) {
                position: absolute;
                padding: 0;
                margin: 0;
                width: calc(50% - #{rem-calc(15)});
                top: rem-calc(20);
                bottom: 0;
                right: rem-calc(15);
            }

            @include breakpoint(1300) {
                width: calc(50% + #{rem-calc(80)});
                right: rem-calc(-80);
            }

            .tps-billboard--tall & {
                order: -1;

                @include breakpoint(tablet) {
                    order: 0;
                }
            }
        }

        &-nextimage {
            display: none;
            position: absolute;
            opacity: 0.5;
            width: rem-calc(20);
            right: 0;
            top: rem-calc(20);
            bottom: rem-calc(60);
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position: top left;

            @include breakpoint(tablet) {
                display: block;
            }
        }

        &-header {
            font-size: rem-calc(50);
            line-height: rem-calc(66);

            @include breakpoint(large) {
                font-size: rem-calc(60);
                line-height: rem-calc(70);
            }

            .tps-billboard--yellow & {
                color: $black;
            }

            .tps-billboard--navy & {
                color: $white;
            }
        }

        &-description {
            margin-top: rem-calc(30);
            font-size: rem-calc(18);
            line-height: rem-calc(28);

            @include breakpoint(tablet) {
                margin-top: rem-calc(22);
            }

            .tps-billboard--yellow & {
                color: $black;
            }

            .tps-billboard--navy & {
                color: $white;
            }
        }

        &-button {
            margin: rem-calc(45) 0 0 0;

            .tps-billboard--yellow & {
                @include button-solid($color-brand-blue-dark, $color-white-absolute);
            }

            .tps-billboard--navy & {
                @include button-solid($color-brand-yellow, $color-brand-blue-dark);
            }
        }
    }
    // Foundation Orbit overrides.
    .orbit-bullets {
        margin: 0;
        padding: rem-calc(25) 0;
        font-size: 0;

        @include breakpoint(tablet) {
            position: absolute;
            bottom: rem-calc(20);
            padding-bottom: rem-calc(45);
            width: 100%;
            text-align: left;
        }

        button {
            margin: 0 rem-calc(9) 0 0;
            width: rem-calc(10);
            height: rem-calc(10);
            border-radius: 50%;
            opacity: 0.5;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            &.is-active {
                opacity: 1;
            }
        }
    }

    .orbit-controls {
        position: absolute;
        width: 100%;
        padding-bottom: 75%;

        @include breakpoint(tablet) {
            position: static;
            padding: 0;
        }
    }

    .orbit-previous, .orbit-next {
        transform: none;
        top: auto;
        bottom: 0;
        padding: rem-calc(11.5) rem-calc(12.75);
        background: $white;
        color: $color-brand-blue-dark;
        font-size: rem-calc(20);
        line-height: 1;
        cursor: pointer;

        @include breakpoint(tablet) {
            top: 50%;
            bottom: auto;
            transform: translateY(-50%);
        }
    }
}
