
.tps-site-header {

    &-utility-menu {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        &-list {

            .tps-site-header-utility-menu > & {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                align-items: center;
                margin: rem-calc(5) 0;
            }

            .tps-site-header-utility-menu-list-item > & {
                display: none;
            }

            .expanded > & {
                border-top: rem-calc(1) solid rgba($color-white, 0.3);
                display: block;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                background-color: $color-brand-blue-dark;
                z-index: 10;
            }

            &-item {
                display: flex;
                margin: 0;
                position: relative;

                &.level-1 {
                    margin: 0 rem-calc(5);
                }

                &.expanded {
                    background-color: $color-brand-blue-dark;
                }

                &-link,
                a:not(.button):not(.button-link) {
                    display: block;
                    color: $color-white;
                    padding: rem-calc(10);

                    &:hover {
                        text-decoration: $anchor-text-decoration;
                    }

                    .level-1 > & {
                        text-decoration: underline;

                        @include breakpoint(large only) {
                            font-size: rem-calc(16);
                        }
                    }

                    .level-2 > & {
                        border-bottom: rem-calc(1) solid rgba($color-white, 0.3);

                        &.tps-site-header-utility-menu-list-item-link,
                        &:not(.button):not(.button-link) {
                            text-decoration: none;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }

                    .expanded > & {
                        background-color: $color-brand-blue-dark;
                    }


                    &:hover,
                    &:active,
                    &:visited,
                    &:focus {
                        color: $color-white;
                    }

                    &.tps-menu-link-toggle {
                        border: 0;
                        margin-left: rem-calc(-7);
                        padding-left: rem-calc(5);
                        text-decoration: none;
                    }
                }


                .button {
                    margin: 0;

                    &.white.hollow {
                        &:focus {
                            color: $color-white;
                            border-color: $color-white;
                        }
                    }
                }
            }
        }
    }
}
