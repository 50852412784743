@mixin button-chevron-forward($color, $hoverColor) {
    $fillColor: str-replace('' + $color, '#', '%23');
    $hoverFillColor: str-replace('' + $hoverColor, '#', '%23');

    &::after {
        content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.7545 6L0.68457 10.0699L2.11555 11.5L7.61464 6L2.11555 0.5L0.68457 1.93007L4.7545 6Z' fill='#{$fillColor}'/%3E%3C/svg%3E");
        display: inline-block;
        margin-left: rem-calc(10);
    }

    &:hover {
        &::after {
            content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.7545 6L0.68457 10.0699L2.11555 11.5L7.61464 6L2.11555 0.5L0.68457 1.93007L4.7545 6Z' fill='#{$hoverFillColor}'/%3E%3C/svg%3E");
        }
    }
}

@mixin button-solid($bgColor, $labelColor) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border: rem-calc(2) solid $bgColor;
    background-color: $bgColor;
    color: $labelColor;
    @include button-chevron-forward($labelColor, $bgColor);

    &:hover {
        border-color: $bgColor;
        background-color: transparent;
        color: $bgColor;
    }

    &:focus {
        outline: rem-calc(1) solid $bgColor;
        outline-offset: rem-calc(2);
    }
}

@mixin button-outline($labelColor, $labelHoverColor) {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    border: rem-calc(2) solid $labelColor;
    background-color: transparent;
    color: $labelColor;
    @include button-chevron-forward($labelColor, $labelHoverColor);

    &:hover {
        border-color: $labelColor;
        background-color: $labelColor;
        color: $labelHoverColor;
    }

    &:focus {
        outline: rem-calc(1) solid $labelColor;
        outline-offset: rem-calc(2);
        color: $labelColor;
        border-color: $labelColor;
    }
}

@mixin button-link-fa-icon-left($unicode, $color) {
    &::before {
        content: $unicode;
        display: inline-block;
        margin-right: rem-calc(8);
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: $font-icons;
        font-weight: 400;
        color: $color;
        font-size: 105%;
    }
}

@mixin focus-solid($boxShadowColor, $outlineColor, $borderRadius) {
    &:focus {
        box-shadow: 0 0 0 rem-calc(1) $boxShadowColor;
        outline: rem-calc(2) solid $outlineColor;
        border-radius: rem-calc($borderRadius);
    }
}
