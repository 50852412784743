.tps-simple-callout {
    padding: rem-calc(35) rem-calc(20) rem-calc(45) rem-calc(20);
    background: $color-gray-desert-storm;
    border: rem-calc(1) solid $color-gray-alto-2;

    @include breakpoint(tablet) {
        padding: rem-calc(35) rem-calc(35) rem-calc(45) rem-calc(35);
    }

    &-header {
        margin-bottom: rem-calc(25);
        font-weight: 700;
        font-size: rem-calc(30);
        line-height: 1.6;
        color: $color-blue-orient;
    }

    &-content {

        p:last-child {
            margin-bottom: 0;
        }
    }
}
