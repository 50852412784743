

.tps-global-search {

    &-message {

        @include breakpoint(medium down) {
            margin-bottom: rem-calc(40);
        }

    }

    &-list {
        max-width: rem-calc(1000);
        margin: 0 auto;
    }

    &-item {
        padding: rem-calc(25) 0;

        &-title {
            font-size: rem-calc(20);
            line-height: 1.6;
            margin-bottom: rem-calc(14);
            text-decoration: underline;
        }

        &-description {

            &-text {
                margin-bottom: rem-calc(15);
            }

        }

        &-tag {
            background-color: $color-brand-yellow;
            display: inline-block;
            font-size: rem-calc(14);
            font-weight: $font-weight-bold;
            line-height: 1.1;
            padding: rem-calc(4);
            text-transform: uppercase;
            letter-spacing: rem-calc(1);
        }

    }

}
