html, body {
    height: 100%; // for sticky footer
}

html {
    font-size: 100%;
}

body {
    display: flex; // for sticky footer
    flex-direction: column; // for sticky footer
    font-size: rem-calc(18);
}

a {
    &:not(.button):not(.button-link) {
        text-decoration: $anchor-text-decoration;

        &:hover {
            text-decoration: $anchor-text-decoration-hover;
        }
    }
}

strong, .fr-wrapper .fr-view strong {
    font-weight: $font-weight-bold;
}

.ui-autocomplete.ui-front {
    z-index: $reveal-zindex + 1;
}

// Editors, resource detail content etc.
.user-content {
    p, ol, ul, h1, h2, h3, h4, h5, h6 {
        margin-bottom: rem-calc(24);
    }

    ol {
        margin-left: 1.75rem;
    }

    ul li::marker {
        color: $color-brand-blue-dark;
    }

    h1, h2, h3, h4, h5, h6 {
        color: $color-brand-blue-dark;
    }

    h1, .is-like-h1 {
        font-size: rem-calc(36);
    }

    h2 {
        font-size: rem-calc(30);
    }

    h3 {
        font-size: rem-calc(24);
    }

    h4 {
        font-size: rem-calc(22);
    }
}

.image-full-width, .fr-view .image-full-width {
    width: 100% !important;
    height: auto;
    max-width: auto;
}

.fr-view img.fr-dii.fr-fil {
    float: left;
    margin: 0 rem-calc(20) 0 0;
}

.fr-view img.fr-dii.fr-fir {
    float: right;
    margin: 0 0 0 rem-calc(20);
}

.fr-view {
    p:last-child {
        margin-bottom: 0;
    }
}
