.tps-accordion {
    margin-left: rem-calc(-10);
    margin-right: rem-calc(-10);
    background-color: transparent;

    @include breakpoint(medium) {
        margin-left: rem-calc(-15);
        margin-right: rem-calc(-15);
    }

    @include breakpoint(tablet) {
        margin-left: 0;
        margin-right: 0;
    }

    .tps-modal & {
        margin-left: 0;
        margin-right: 0;
    }

    &-item {
        background-color: $color-brand-gray-light;
        margin-bottom: rem-calc(6);

        &:last-child {
            margin-bottom: 0;
        }

        .tps-modal & {
            background-color: $color-brand-blue-light;
        }
    }

    &-title {
        font-size: rem-calc(18);
        font-weight: 700;

        @include breakpoint(tablet) {
            font-size: rem-calc(22);
            padding: rem-calc(18) rem-calc(100) rem-calc(23) rem-calc(20);
        }

        .tps-modal & {
            font-size: rem-calc(16);
            font-weight: 400;
            padding: rem-calc(10) rem-calc(30);
            background-color: $color-brand-blue-light;

            &:hover,
            &:active,
            &:focus,
            &:focus-visible {
                background-color: $color-brand-blue-light;
            }
        }


        &:before {
            font-size: rem-calc(22);
            font-weight: 400;
            font-family: $font-icons;
            margin-top: rem-calc(-11);

            .tps-modal & {
                font-size: rem-calc(14);
                margin-top: rem-calc(-7);
                left: rem-calc(10);
                right: auto;
            }
        }

        &-info {

            .tps-modal & {
                font-size: rem-calc(14);
                color: $color-brand-gray-dark;
                line-height: 1;
                position: absolute;
                right: rem-calc(38);
                top: 50%;
                margin-top: rem-calc(-7);
            }
        }
    }

    &-content {

        @include breakpoint(tablet) {
            padding-right: rem-calc(20);
            padding-left: rem-calc(20);
            padding-bottom: rem-calc(30);
        }

        .tps-modal & {
            background-color: $color-white;
            padding-top: rem-calc(15);
        }

        > *:last-child {
            margin-bottom: 0;
        }

        strong {
            font-weight: 700;
        }
    }
}
