
.tps-site-header {
    background-color: $color-white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 90;

    &-top {
        background-color: $color-white;
        max-height: rem-calc(90);

        @include breakpoint(large) {
            background-color: $color-brand-blue-dark;
            transition: max-height 0.15s ease-in-out;
        }

        &-inner {
            padding: rem-calc(10);

            @include breakpoint(large) {
                padding-top: rem-calc(15);
                padding-right: 0;
                padding-bottom: rem-calc(15);
                padding-left: 0;
            }
        }

        body.tps-header-sticky & {
            @include breakpoint(large) {
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.15s ease-in-out;
            }
        }
    }

    &-logo {
        background-image: url('../img/onecap-logo-header.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        display: block;
        height: 0;
        padding-bottom: 41%;
        width: rem-calc(145);

        html[lang="fr-CA"] & {
            background-image: url('../img/onecap-logo-header-FR.png');
        }

        @include breakpoint(large) {
            background-image: url('../img/onecap-logo-header-white.png');

            html[lang="fr-CA"] & {
                background-image: url('../img/onecap-logo-header-white-FR.png');
            }
        }
    }

    &-mobile-navigation-toggle {
        display: block;
        padding: rem-calc(10) 0;
        width: rem-calc(35);

        .tps-icon {
            display: block;

            span,
            &:before,
            &:after {
                background-color: $color-brand-blue-dark;
                display: block;
                height: rem-calc(4);
                margin-bottom: rem-calc(8);
            }

            &:before,
            &:after {
                content: "";
            }

            &:after {
                margin-bottom: 0;
            }
        }
    }

    &-apply-wrapper {
        background-color: $color-gray-desert-storm;
        padding: rem-calc(15) 0;
        text-align: center;

        .button {
            width: 100%;
            margin: 0;
        }
    }

    &-bottom {
        background-color: $color-white;
        padding: 0;
        position: relative;
    }

    &-search {
        display: flex;
        align-items: stretch;

        &-input {
            border: rem-calc(1) solid $color-gray-fuscous;
            color: $color-gray-fuscous;
            font-size: rem-calc(16);
            height: auto;
            margin-bottom: 0;
            padding-right: rem-calc(13);
            padding-left: rem-calc(13);

            &::placeholder {
                color: $color-gray-fuscous;
            }

            @include focus-solid($white, $black, 5);
        }

        &-submit {
            background-image: url('../img/search-icon-blue-dark.svg');
            background-repeat: no-repeat;
            background-position: right center;
            background-size: rem-calc(19.5);
            width: rem-calc(34.75);
        }
    }
}
