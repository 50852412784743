.listing-page {
    &-title {
        margin: rem-calc(20) 0 rem-calc(30) 0;
        font-size: rem-calc(50);
        line-height: rem-calc(60);
        text-align: center;

        @include breakpoint(large) {
            margin: rem-calc(45) 0 rem-calc(60) 0;
            font-size: rem-calc(60);
            line-height: rem-calc(93);
        }
    }

    &-filters {
        padding: rem-calc(35) 0 rem-calc(30) 0;

        @include breakpoint(tablet) {
            margin-left: rem-calc(15);
            margin-right: rem-calc(15);
        }

        &.global-search-filters {
            max-width: rem-calc(1000);

            @include breakpoint(tablet) {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &-item {
            margin-bottom: rem-calc(20);

            &-title {
                margin-bottom: rem-calc(14);
                font-size: rem-calc(18);
                font-weight: 700;
                line-height: 1.45;

                @include breakpoint(large) {
                    font-size: rem-calc(22);
                }
            }

            .global-search-filters & {
                margin-bottom: 0;
            }

            &.inline {
                display: flex;
                align-items: center;
                justify-content: center;

                label {
                    margin-bottom: rem-calc(0);
                    margin-right: rem-calc(20);
                }
            }

            &-search {
                display: flex;

                input {
                    margin: 0;
                    padding: rem-calc(10) rem-calc(18);
                    border: rem-calc(1) solid $black;
                    border-right: 0;
                    height: auto;
                    font-size: rem-calc(18);

                    @include focus-solid($white, $black, 5);
                }

                &-submit {
                    flex-shrink: 0;
                    margin: 0;
                    width: rem-calc(49);
                    background-color: $color-brand-blue-dark;
                    color: $white;
                    cursor: pointer;
                }
            }
        }

        select {
            margin: 0;
            padding: rem-calc(10) rem-calc(13+15+13) rem-calc(10) rem-calc(18);
            border: rem-calc(1) solid $black;
            background-image: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.7383 1.16992C14.8047 1.26953 14.8379 1.36914 14.8379 1.46875C14.8379 1.60156 14.8047 1.66797 14.7383 1.73438L7.79883 8.70703C7.69922 8.77344 7.59961 8.80664 7.5 8.80664C7.36719 8.80664 7.30078 8.77344 7.23438 8.70703L0.261719 1.73438C0.195312 1.66797 0.162109 1.60156 0.162109 1.46875C0.162109 1.36914 0.195312 1.26953 0.261719 1.16992L0.925781 0.505859C0.992188 0.439453 1.05859 0.40625 1.19141 0.40625C1.29102 0.40625 1.39062 0.439453 1.49023 0.505859L7.5 6.51562L13.5098 0.505859C13.5762 0.439453 13.6758 0.40625 13.8086 0.40625C13.9082 0.40625 14.0078 0.439453 14.0742 0.505859L14.7383 1.16992Z' fill='black'/%3E%3C/svg%3E");
            background-size: rem-calc(15) rem-calc(9);
            background-position: right rem-calc(13) center;
            background-origin: border-box;
            height: auto;
            font-size: rem-calc(18);

            @include focus-solid($white, $black, 5);

            @include breakpoint(large) {
                max-width: rem-calc(200);
            }
        }
    }

    &-results {
        margin-top: rem-calc(60);
    }

    &-pagination {
        align-items: center;
        border-top: rem-calc(1) solid $color-gray-alto;
        display: flex;
        justify-content: center;
        margin: rem-calc(45) 0;
        padding: rem-calc(35) 0;

        &-inner {
            align-items: center;
            display: flex;
            flex: 0 1 auto;
            justify-content: center;
        }

        &-number, &-arrow {
            border-radius: 50%;
            color: $color-blue-orient;
            cursor: pointer;
            display: inline-block;
            font-size: rem-calc(16);
            line-height: rem-calc(40);
            width: rem-calc(40);
            height: rem-calc(40);
            text-align: center;
        }

        &-arrow {
            background: $color-blue-humming-bird;

            &[disabled],
            &[aria-disabled] {
                background: $color-gray-desert-storm;
                color: $color-gray-fuscous;
                cursor: not-allowed;
            }

            &.left {
                margin-right: rem-calc(10);
            }

            &.right {
                margin-left: rem-calc(10);
            }
        }

        &-number {
            margin: 0 rem-calc(5);

            &.current {
                background: $color-gray-desert-storm;
                cursor: not-allowed;
                pointer-events: none;
            }
        }
    }
}
