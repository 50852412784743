

.tps-bios-list {

    &-item {
        padding-top: rem-calc(35);
        margin-bottom: rem-calc(60);

        @include breakpoint(tablet) {
            padding-top: rem-calc(85);
            margin-bottom: rem-calc(105);
        }

        &:last-child {
            margin-bottom: rem-calc(15);
            margin-bottom: rem-calc(80);
        }


        &-header {
            background-color: $color-brand-gray-light;
            display: flex;
            flex-direction: column;
            margin-right: rem-calc(-10);
            margin-bottom: rem-calc(40);
            margin-left: rem-calc(-10);
            padding: rem-calc(25);
            padding-top: 0;
            padding-bottom: rem-calc(40);



            @include breakpoint(medium) {
                flex-direction: row;
                align-items: center;
                margin-right: rem-calc(-15);
                margin-left: rem-calc(-15);
            }

            @include breakpoint(tablet) {
                margin-bottom: rem-calc(55);
                margin-right: 0;
                margin-left: 0;
            }

            &.light-blue {
                background-color: $color-brand-blue-light;
            }

            &.dark-blue {
                background-color: $color-brand-blue-dark;
            }


            @include breakpoint(tablet) {
                padding-bottom: rem-calc(20);
            }

            &-image {
                margin-top: rem-calc(-35);

                @include breakpoint(tablet) {
                    margin-top: rem-calc(-85);
                }

                &-inner {
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    height: rem-calc(190);
                    width: rem-calc(160);

                    @include breakpoint(tablet) {
                        height: rem-calc(274);
                        width: rem-calc(228.5);
                    }
                }
            }

            &-text {
                padding-top: rem-calc(30);

                @include breakpoint(medium) {
                    padding-left: rem-calc(30);
                }

                @include breakpoint(tablet) {
                    padding-left: rem-calc(45);
                }

                @include breakpoint(large) {
                    padding-left: rem-calc(60);
                }
            }

            &-name {
                color: $color-brand-blue-dark;
                font-size: rem-calc(30);
                font-weight: $font-weight-bold;
                line-height: 1.33;
                margin: 0;

                .dark-blue & {
                    color: $color-white;
                }
            }

            &-job-title {
                color: $color-black;
                background-color: $color-brand-yellow;
                display: inline-block;
                font-size: rem-calc(16);
                font-weight: $font-weight-bold;
                line-height: rem-calc(18);
                margin-top: rem-calc(15);
                padding: rem-calc(3) rem-calc(6);
                text-transform: uppercase;

                @include breakpoint(medium) {
                    white-space: pre-wrap;
                }
            }
        }


        &-content {
        }
    }
}
