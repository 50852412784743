
.glide {

    &__slides {
        align-items: stretch;
    }

    &__slide {

        &.tps-testimonials-list-item {
            height: auto;
        }
    }

    &__bullets {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    &__bullet {
        background-color: $color-brand-blue-dark;
        border-radius: rem-calc(4.5);
        margin: rem-calc(7);
        width: rem-calc(9);
        height: rem-calc(9);

        &--active {
            background-color: $color-brand-yellow;
        }
    }

    &__arrow {
        background-color: $color-brand-yellow;
        color: $color-brand-blue-dark;
        padding: rem-calc(10.5);
        position: absolute;
        bottom: 0;

        @include breakpoint(xlarge) {
            bottom: 50%;
            margin-bottom: rem-calc(-19);
        }

        &--left {
            left: 0;

            @include breakpoint(xlarge) {
                left: auto;
                right: 100%;
            }

            @include breakpoint(1300px) {
                margin-right: rem-calc(60)
            }

            @include breakpoint(xxlarge) {
                margin-right: rem-calc(90)
            }
        }

        &--right {
            right: 0;

            @include breakpoint(xlarge) {
                right: auto;
                left: 100%;
            }

            @include breakpoint(1300px) {
                margin-left: rem-calc(60)
            }

            @include breakpoint(xxlarge) {
                margin-left: rem-calc(90)
            }
        }
    }
}
