
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-family-sans: sofia-pro, Arial, sans-serif;
$font-family-serif: 'Times New Roman', serif;

$font-icons: "Font Awesome 5 Pro";

// ## FOUNDATION ##

$body-font-color: $color-black;
$body-font-family: $font-family-sans;

$global-weight-normal: $font-weight-regular;
$global-weight-bold: $font-weight-bold;
