.tps-cta-cards {
    $cards-gap: rem-calc(20);
    margin: 0 #{-$cards-gap/2} #{-$cards-gap} #{-$cards-gap/2};

    @include breakpoint(tablet) {
        display: flex;
        flex-wrap: wrap;

        &--per-row-1 {
            margin-bottom: rem-calc(-55);
        }

        &--alignment-bottom {
            align-items: flex-end;
        }
    }

    &-item {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 $cards-gap/2 $cards-gap $cards-gap/2;
        width: calc(100% - #{$cards-gap});

        @include breakpoint(tablet) {
            .tps-cta-cards--per-row-1 & {
                flex-direction: row;
                margin-bottom: rem-calc(55);
            }

            .tps-cta-cards--per-row-2 & {
                width: calc(50% - #{$cards-gap});
            }

            .tps-cta-cards--per-row-3 & {
                width: calc(33.33% - #{$cards-gap});
            }
        }

        &--with-image {
            &::before {
                content: '';
                position: absolute;
                top: rem-calc(20);
                left: 0;
                right: 0;
                bottom: 0;
            }

            @include breakpoint(tablet) {
                &.tps-cta-cards-item--image-left::before {
                    top: 0;
                    left: rem-calc(20);
                }

                &.tps-cta-cards-item--image-right::before {
                    right: rem-calc(20);
                    top: 0;
                }
            }
        }

        &--bg-white {
            &:not(.tps-cta-cards-item--with-image), &.tps-cta-cards-item--with-image::before {
                background: $white;
            }
        }

        &--bg-gray-light {
            &:not(.tps-cta-cards-item--with-image), &.tps-cta-cards-item--with-image::before {
                background: $color-brand-gray-light;
            }
        }

        &--bg-blue-light {
            &:not(.tps-cta-cards-item--with-image), &.tps-cta-cards-item--with-image::before {
                background: $color-brand-blue-light;
            }
        }

        &-image {
            position: relative;
            margin-right: rem-calc(20);
            width: calc(100% - #{rem-calc(20)});
            padding-bottom: 70%;
            background-size: cover;
            background-position: center;

            @include breakpoint(tablet) {
                .tps-cta-cards-item--image-left &, .tps-cta-cards-item--image-right & {
                    flex: 0 0 auto;
                    margin-top: rem-calc(20);
                    margin-right: 0;
                    width: calc(50% + #{rem-calc(20)});
                    padding-bottom: 30%;
                }

                .tps-cta-cards-item--image-right & {
                    order: 1;
                }
            }
        }

        &-content {
            position: relative;
            padding: rem-calc(25) rem-calc(16) rem-calc(30) rem-calc(16);

            @include breakpoint(tablet) {
                padding: rem-calc(30) rem-calc(30) rem-calc(50) rem-calc(30);

                .tps-cta-cards--alignment-equal & {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    &-footer {
                        margin-top: auto;
                    }
                }

                .tps-cta-cards--per-row-1 & {
                    align-self: center;
                    padding: rem-calc(50) rem-calc(65);
                }
            }
        }

        &-header {
            font-size: rem-calc(30);
            line-height: rem-calc(40);
            color: $color-brand-blue-dark;
        }

        &-description {
            margin-top: rem-calc(25);
            font-size: rem-calc(18);
            line-height: rem-calc(28);

            @include breakpoint(tablet) {
                .tps-cta-cards--per-row-1 & {
                    margin-top: rem-calc(30);
                }
            }
        }

        &-button {
            margin-top: rem-calc(40);
            margin-bottom: 0;

            @include breakpoint(tablet) {
                .tps-cta-cards--per-row-1 & {
                    margin-top: rem-calc(45);
                }
            }
        }
    }
}
