.tps-featured-resource {
    position: relative;
    margin-left: rem-calc(-10);
    margin-right: rem-calc(-10);

    @include breakpoint(medium) {
        margin-left: rem-calc(-15);
        margin-right: rem-calc(-15);
    }

    @include breakpoint(tablet) {
        display: flex;
        margin-left: 0;
        margin-right: 0;
    }

    &::before {
        content: '';
        position: absolute;
        top: rem-calc(20);
        left: 0;
        right: 0;
        bottom: 0;

        @include breakpoint(tablet) {
            top: 0;
            left: rem-calc(20);
            bottom: rem-calc(20);
        }
    }

    &--yellow::before {
        background-color: $color-brand-yellow;
    }

    &--navy::before {
        background-color: $color-brand-blue-dark;
    }

    &-image {
        position: relative;
        margin-left: rem-calc(20);
        width: calc(100% - #{rem-calc(20)});
        padding-bottom: 62.5%;
        background-size: cover;
        background-position: center;

        @include breakpoint(tablet) {
            flex-shrink: 0;
            margin-left: 0;
            margin-top: rem-calc(20);
            width: 57.5%;
            padding-bottom: 35.93%; // proportional to mobile 62.5%
        }
    }

    &-content {
        position: relative;
        padding: rem-calc(30) rem-calc(20) rem-calc(60) rem-calc(20);

        @include breakpoint(tablet) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: rem-calc(40);
        }
    }

    &-category {
        display: inline-block;
        padding: rem-calc(2.75) rem-calc(5.5);
        background-color: $white;
        font-size: rem-calc(14);
        font-weight: 700;
        line-height: 1;
        letter-spacing: rem-calc(1);
        color: $black;
        text-transform: uppercase;
        vertical-align: middle;
    }

    &-title {
        display: block;
        font-size: rem-calc(22);
        line-height: rem-calc(32);
        font-weight: 700;
        color: $black;

        .tps-featured-resource--navy & {
            color: $white;
        }
    }

    &-title, h2, h3, h4 {
        margin: rem-calc(30) 0 0 0;

        @include breakpoint(tablet) {
            margin: rem-calc(22) 0 0 0;
        }
    }

    &-info {
        display: grid;
        grid-template-columns: minmax(#{rem-calc(80)}, max-content) min-content 1fr;
        margin-top: rem-calc(5);
        font-size: rem-calc(16);
        line-height: 1.75;
        color: $color-gray-fuscous;

        .tps-featured-resource--navy & {
            color: $color-gray-alto;
        }
    }

    &-description {
        margin-top: rem-calc(18);
        font-size: rem-calc(18);
        line-height: 1.56;
        color: $black;

        .tps-featured-resource--navy & {
            color: $white;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &-footer {
        margin-top: rem-calc(50);

        @include breakpoint(tablet) {
            margin-top: rem-calc(40);
        }
    }

    &-button {
        margin: 0;

        .tps-featured-resource--yellow & {
            @include button-solid($color-brand-blue-dark, $color-white-absolute);
        }

        .tps-featured-resource--navy & {
            @include button-solid($color-brand-yellow, $color-brand-blue-dark);
        }
    }
}
