
.tps-page-section {

    &-inner {
        position: relative;
    }

    &-background-grid {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
    }
    
    &.section-bg-white { background-color: $color-white; }
    &.section-bg-yellow { background-color: $color-brand-yellow; }
    &.section-bg-light-blue { background-color: $color-brand-blue-light; }
    &.section-bg-beige { background-color: $color-gray-desert-storm; }

    .grid-container {
        position: relative;
        z-index: 1;
    }

    &-column {
        .padding-top & { padding-top: rem-calc(45); }
        .padding-bottom & { padding-bottom: rem-calc(45); }
    }

    .cell {
        &.section-cell-bg-white { background-color: $color-white; }
        &.section-cell-bg-yellow { background-color: $color-brand-yellow; }
        &.section-cell-bg-light-blue { background-color: $color-brand-blue-light; }
        &.section-cell-bg-beige { background-color: $color-gray-desert-storm; }
    }

}
