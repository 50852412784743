.tps-resource-page {
    &-billboard {
        position: relative;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: rem-calc(20);
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $color-brand-gray-light;

            @include breakpoint(tablet) {
                top: rem-calc(10);
                left: 0;
                right: 0;
                bottom: rem-calc(10);
            }

            @include breakpoint(1300) {
                top: rem-calc(10);
                left: rem-calc(20);
                right: rem-calc(20);
                bottom: rem-calc(10);
            }
        }

        > .grid-container {
            position: relative;

            @include breakpoint(tablet) {
                display: flex;
            }
        }

        &-image {
            margin-right: rem-calc(-10);
            padding-bottom: 62.5%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            @include breakpoint(medium) {
                margin-right: rem-calc(-15);
            }

            @include breakpoint(tablet) {
                margin: 0;
                padding: 0;
                width: 50%;
                flex-shrink: 0;
            }
        }

        &-content {
            padding: rem-calc(35) 0 rem-calc(40) 0;

            @include breakpoint(tablet) {
                padding: rem-calc(30) rem-calc(20) rem-calc(30) rem-calc(40);
            }

            @include breakpoint(large) {
                padding: rem-calc(40) rem-calc(20) rem-calc(40) rem-calc(75);
            }
        }

        &-category {
            display: inline-block;
            padding: rem-calc(2.75) rem-calc(5.5);
            background-color: $color-brand-yellow;
            font-size: rem-calc(14);
            line-height: 1;
            letter-spacing: rem-calc(1);
            color: $black;
            text-transform: uppercase;
        }

        &-title {
            margin: rem-calc(18) 0 rem-calc(30) 0;
            font-size: rem-calc(35);
            line-height: rem-calc(45);
            color: $color-brand-blue-dark;

            @include breakpoint(large) {
                font-size: rem-calc(40);
                line-height: rem-calc(48);
            }
        }

        &-info {
            font-weight: 500;
            font-size: rem-calc(20);
            line-height: 1.6;
            color: $black;

            @include breakpoint(tablet) {
                display: grid;
                grid-template-columns: minmax(#{rem-calc(80)}, max-content) min-content 1fr;
            }
        }

        &-share {
            display: flex;
            align-items: center;
            margin-top: rem-calc(50);
            font-size: rem-calc(18);
            font-weight: 500;

            @include breakpoint(large) {
                margin-top: rem-calc(70);
            }

            &-links {
                margin-left: rem-calc(26);

                a {
                    margin-right: rem-calc(10);
                    color: $color-brand-blue-dark;

                    &:hover {
                        color: $color-brand-yellow;
                    }
                }
            }
        }
    }

    &-content {
        margin-bottom: rem-calc(50);
        padding-top: rem-calc(40);

        @include breakpoint(tablet) {
            margin-bottom: rem-calc(60);
            padding-top: rem-calc(50);
        }
    }

    &-author {
        display: flex;
        align-items: center;
        padding: rem-calc(30) 0 rem-calc(50) 0;
        border-top: rem-calc(1) solid lighten($color-gray-alto, 2.35);

        @include breakpoint(tablet) {
            padding-bottom: rem-calc(80);
        }

        &-photo {
            width: rem-calc(60);
            height: rem-calc(60);
            object-fit: cover;
            border-radius: 50%;

            @include breakpoint(tablet) {
                width: rem-calc(80);
                height: rem-calc(80);
            }
        }

        &-content {
            padding-left: rem-calc(15);
            line-height: 1.6;

            @include breakpoint(tablet) {
                padding-left: rem-calc(20);
            }
        }

        &-title {
            margin-bottom: rem-calc(6);
            font-size: rem-calc(20);
            font-weight: 500;
            color: $black;
        }

        &-description {
            font-size: rem-calc(18);
            color: $color-gray-fuscous;

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-related {
        padding: rem-calc(45) 0;
        background-color: $color-brand-blue-light;

        &-title {
            margin-bottom: rem-calc(30);
            font-size: rem-calc(35);
            color: $color-brand-blue-dark;

            @include breakpoint(tablet) {
                font-size: rem-calc(40);
            }
        }
    }
}
