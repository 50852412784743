@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.tps-loader {
    position: fixed;
    z-index: 99999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba($white, 0.8);

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        height: rem-calc(46);
        width: rem-calc(46);
        border: rem-calc(5) solid $white;
        border-color: $color-brand-blue-dark transparent $color-brand-blue-dark transparent;
        border-radius: 50%;
        animation: loader-spin 1.2s linear infinite;
    }
}
