
.tps-site-navigation {

    &-list {
        margin: 0;
        // the first level list
        // is a flexbox
        .tps-site-navigation > & {
            display: flex;
        }

        .tps-site-navigation-list-item > & {
            display: none;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 100%;
            right: 0;
            left: 0;
            background-color: $color-gray-desert-storm;
            z-index: 1;
        }

        .expanded > & {
            display: flex;
        }

        &-item {
            display: flex;

            &.level-2 {
                padding: rem-calc(14) 0;

                @include breakpoint(xlarge) {
                    padding-right: rem-calc(14);
                    padding-left: rem-calc(14);
                }
            }

            &-link,
            a:not(.button):not(.button-link) {
                color: $color-brand-blue-dark;
                position: relative;
                text-decoration: none;
                z-index: 1;

                .level-1 > & {
                    display: block;
                    padding: rem-calc(14.5) rem-calc(19);

                    @include breakpoint(large only) {
                        font-size: rem-calc(16);
                    }
                }

                .level-1.expanded > & {
                    background-color: $color-gray-desert-storm;
                }


                &.is-active-path {
                    position: relative;

                    .level-1 > & {
                        &:after {
                            background-color: $color-brand-yellow;
                            content: "";
                            display: block;
                            margin-bottom: rem-calc(-3);
                            position: absolute;
                            bottom: rem-calc(13);
                            left: rem-calc(19);
                            right: rem-calc(19);
                            height: rem-calc(3);
                            z-index: 0;
                        }
                    }
                }

                &.tps-menu-link-toggle {
                    margin-left: rem-calc(-20);
                    z-index: 2;
                }

                .level-2 & {
                    padding: rem-calc(5) rem-calc(15);
                }

                &:hover,
                &:active,
                &:visited,
                &:focus {
                    color: $color-brand-blue-dark;
                }
            }
        }
    }
}


.tps-menu-link-toggle {
    .level-1.expanded > & {
        .tps-icon {
            &:before {
                content: "\f106";
            }
        }
    }
}
