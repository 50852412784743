
.tps-modal {
    padding: 0;

    &-close {
        font-size: rem-calc(25);
        right: rem-calc(24);
        top: rem-calc(20);

        .tps-modal-cookies-settings & {
            background-color: $color-brand-blue-dark;
            color: $color-white;
        }
    }

    &-header {
        font-size: rem-calc(22);
        line-height: 1.54;
        padding: rem-calc(22.5) rem-calc(15) rem-calc(11.5);

        @include breakpoint(tablet) {
            padding-right: rem-calc(30);
            padding-left: rem-calc(30);
        }

        &.dark-blue {
            background-color: $color-brand-blue-dark;
            color: $color-white;
        }

        &.light-blue {
            background-color: $color-brand-blue-light;
        }

        &.light-grey {
            background-color: $color-brand-gray-light;
        }
    }

    &-content {
        font-size: rem-calc(16);
        padding: rem-calc(22.5) rem-calc(15);

        @include breakpoint(tablet) {
            padding-right: rem-calc(30);
            padding-left: rem-calc(30);
        }

        .white + & {
            padding-top: 0;
        }

        *:last-child {
            margin-bottom: 0;
        }

        &-header {
            color: $color-brand-blue-dark;
            font-weight: $font-weight-bold;
            margin-bottom: rem-calc(5);
        }

        .tps-accordion {
            margin-top: rem-calc(15);

            .tps-accordion-item {
                position: relative;

                .accordion-switch {
                    position: absolute;
                    z-index: 2;
                    top: rem-calc(8);
                    right: rem-calc(5);
                    margin-right: rem-calc(80);

                    .switch-active,
                    .switch-inactive {
                        left: rem-calc(50);
                    }

                    .switch-active {
                        color: $color-brand-blue-dark;
                    }
                }
            }
        }
    }

    &-actions {
        padding: rem-calc(15) rem-calc(15) rem-calc(30) rem-calc(15);

        @include breakpoint(tablet) {
            padding-right: rem-calc(30);
            padding-left: rem-calc(30);
        }

        .button {
            width: 100%;
            margin-bottom: rem-calc(5);

            @include breakpoint(medium) {
                width: auto;
            }
        }

        &.dark-blue {
            background-color: $color-brand-blue-dark;
            color: $color-white;
        }

        &.light-blue {
            background-color: $color-brand-blue-light;
        }

        &.light-grey {
            background-color: $color-brand-gray-light;
        }
    }

    &-cookies-disclaimer {
        width: 100%;

        @include breakpoint(medium) {
            width: rem-calc(550);
        }
    }

    &-cookies-settings {
        width: rem-calc(700);

        &-cookielist {
            &-item {
                border-top: rem-calc(1) solid $color-brand-gray-light;
                padding-top: rem-calc(10);
                padding-bottom: rem-calc(10);
            }
        }
    }
}
