
.tps-site-navigation-mobile {
    background-color: $color-brand-blue-dark;

    &-list {

        .tps-site-navigation-mobile-list-item > & {
            display: none;
        }

        .expanded > & {
            display: block;
        }

        &-item {
            position: relative;

            &-link,
            a:not(.button):not(.button-link) {
                border-bottom: rem-calc(1) solid rgba($color-white, 0.3);
                font-weight: $font-weight-medium;
                color: $color-white;
                display: block;
                line-height: rem-calc(28);
                padding: rem-calc(18.5) rem-calc(22);
                text-decoration: none;

                .level-2 > & {
                    background-color: $color-blue-prussian;
                }

                &:hover,
                &:active,
                &:visited,
                &:focus {
                    color: $color-white;
                }

                &.tps-menu-link-toggle {
                    border-bottom-color: transparent;
                    padding-left: rem-calc(5);
                    padding-right: rem-calc(15);
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
}
