// blues
$color-blue-humming-bird: #E2F4FB;
$color-blue-orient: #00567D;
$color-blue-prussian: #003148;

// grays
$color-gray-desert-storm: #F6F6F4;
$color-gray-alto: #D2D2D2;
$color-gray-alto-2: #E0E0E0;
$color-gray-fuscous: #51514D;
$color-gray-dusty: #979797;

// yellows
$color-yellow-lightning: #FFBE19;

// mapping colors to generic properties
$color-brand-blue-light: $color-blue-humming-bird;
$color-brand-blue-dark: $color-blue-orient;
$color-brand-yellow: $color-yellow-lightning;
$color-brand-gray-light: $color-gray-desert-storm;
$color-brand-gray-medium: $color-gray-alto; 
$color-brand-gray-dark: $color-gray-fuscous;

// B&W absolutes
$color-black-absolute: #000000;
$color-white-absolute: #FFFFFF;

// ## Foundation ##

$foundation-palette: (
    primary: $color-brand-blue-dark, /* Not defined by design */
    secondary: $color-brand-blue-light, /* Not defined by design */
    success: $color-brand-blue-light, /* Not defined by design */
    warning: $color-brand-yellow, /* Not defined by design */
    alert: #cc4b37, /* Not defined by design */
);

$brand-button-palette: (
    navy: $color-brand-blue-dark,
    yellow: $color-brand-yellow,
    white: $color-white-absolute
);

// Grays
$light-gray: $color-brand-gray-light; /* Not defined by design */
$medium-gray: $color-brand-gray-medium; /* Not defined by design */
$dark-gray: $color-brand-gray-dark; /* Not defined by design */

// B&W
$color-black: $color-black-absolute;
$color-white: $color-white-absolute;

$black: $color-black;
$white: $color-white;
