
.tps-site-footer {
    background-color: $color-brand-blue-dark;
    color: $color-white;
    font-weight: $font-weight-light;
    padding: rem-calc(10);
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(50);

    @include breakpoint(large) {
        padding-top: rem-calc(95);
        padding-bottom: rem-calc(35);
    }

    a:not(.button) {
        color: $color-white;

        &:hover,
        &:active,
        &:visited,
        &:focus {
            color: $color-white;
        }
    }

    &-top {
        margin-bottom: rem-calc(50);

        @include breakpoint(large) {
            margin-bottom: rem-calc(89);
        }
    }

    &-logo {
        height: rem-calc(70);
        width: auto;

        &-wrapper {
            text-align: center;
            padding-bottom: rem-calc(30);

            @include breakpoint(large) {
                text-align: left;
            }
        }
    }

    &-menu {

        &-list {
            margin: 0;
            margin-bottom: rem-calc(35);
            margin-right: rem-calc(25);

            @include breakpoint(tablet) {
                margin-right: rem-calc(50);
                margin-bottom: 0;
            }

            @include breakpoint(xlarge) {
                margin-right: rem-calc(75);
            }

            .footer-menu-legal & {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin: 0;

                @include breakpoint(tablet) {
                    justify-content: flex-start;
                    margin-left: rem-calc(50);
                }

                @include breakpoint(xlarge) {
                    margin-left: rem-calc(85);
                }
            }

            &-item {
                margin-bottom: rem-calc(11.25);

                .footer-menu-legal & {
                    margin-left: rem-calc(30);

                    @include breakpoint(tablet down) {
                        margin-left: rem-calc(15);
                    }

                    @include breakpoint(medium down) {
                        margin-right: rem-calc(15);
                        margin-bottom: 0;
                    }
                }

                &-link,
                a:not(.button):not(.button-link) {

                    &:hover {
                        text-decoration: $anchor-text-decoration;
                    }

                    .footer-menu-legal & {
                        display: block;
                        font-size: rem-calc(15);
                        line-height: rem-calc(25);
                    }
                }
            }
        }
    }

    &-apply-wrapper {
        margin-bottom: rem-calc(50);

        @include breakpoint(medium) {
            margin-bottom: rem-calc(72);
        }

        .button {
            margin: 0;
            width: 100%;

            &.yellow.hollow {

                &:focus {
                    color: $color-brand-yellow;
                    border-color: $color-brand-yellow;
                }
            }

            @include breakpoint(medium) {
                width: auto;
            }
        }
    }

    &-static-menu {

        &-list {
            margin: 0;
            display: flex;
            justify-content: space-between;


            @include breakpoint(medium) {
                display: block;
                justify-content: initial;
            }

            &-item {
                flex: 0 0 auto;

                @include breakpoint(medium) {
                    flex: auto;
                    margin-bottom: rem-calc(11.25);
                }

                &-link,
                a:not(.button):not(.button-link) {
                    line-height: rem-calc(28);

                    &:hover {
                        text-decoration: $anchor-text-decoration;
                    }

                    .tps-icon {
                        font-size: rem-calc(18);
                        line-height: rem-calc(28);
                        margin-right: rem-calc(10);
                        width: rem-calc(20);

                        &:before {
                            line-height: rem-calc(28);
                        }
                    }
                }
            }
        }
    }

    &-copyright {
        font-size: rem-calc(15);
        line-height: rem-calc(25);
        text-align: center;
        margin-bottom: rem-calc(20);

        @include breakpoint(tablet) {
            margin-bottom: 0;
        }
    }
}
